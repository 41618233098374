import React from "react";

export const AppFooter = () => {
  return (
    <footer className="bg-slate">
      <div className="container text-center text-sand font-light text-sm p-10">
        <p>©2024 by Noah Fernandes</p>
      </div>
    </footer>
  );
};
