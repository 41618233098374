import React from "react";
import { Button } from "./common/Button";

export const ServicesContent = () => {
  return (
    <div>
      <div className="sm:flex">
        <div className="sm:w-2/6 sm:pr-8">
          <h2 className="text-4xl font-serif">Services and Prices</h2>
        </div>
        <div className="sm:w-4/6">
          <p className="mt-4 sm:mt-0">
            As a relationship coach and counsellor, I am here to assist you in
            various situations, including but not limited to:
          </p>
          <ul className="list-disc ml-4 mt-4">
            <li>
              <strong>Exploring Identity and Authenticity</strong>: If you are
              struggling with your sense of identity or finding it difficult to
              be your authentic self, I can help you explore and embrace who you
              truly are.
            </li>
            <li>
              <strong>Boosting Self-Esteem and Confidence</strong>: Low
              self-esteem and confidence can hold you back in various aspects of
              life. Together, we can work on boosting these to help you thrive
              both personally and professionally.
            </li>
            <li>
              <strong>Navigating Complex Emotions</strong>: Struggling to manage
              intense emotions? I can guide you in understanding and handling
              them effectively.
            </li>
            <li>
              <strong>Healing from Past Traumas</strong>: If past traumas are
              impacting your personal well-being and relationships, I provide a
              safe space to heal and move forward.
            </li>
            <li>
              <strong>Navigating Major Life Transitions</strong>: Major changes
              like marriage, divorce, or career shifts can be challenging. I
              offer support to help you navigate these transitions smoothly.
            </li>
            <li>
              <strong>Improving Communication Skills</strong>: Seeking to deepen
              connections and enhance your relationships through better
              communication? Let's work on honing these essential skills
              together.
            </li>
            <li>
              <strong>Setting and Achieving Relationship Goals</strong>: Whether
              you aim to improve intimacy, resolve conflicts, or strengthen your
              bonds, I can help you set and achieve your relationship goals.
            </li>
          </ul>
          <p className="mt-4">
            If any of these resonate with you, my specialised coaching and
            counselling services are here to support you on your journey to
            personal and relationship well-being.
          </p>

          <div className="bg-sand text-coconut p-2 mt-4">
            <h3>What to Expect During Our Sessions</h3>
          </div>
          <p className="mt-2">
            During our sessions, your story will unfold, and I'll be there,
            listening attentively every step of the way. We'll maintain an
            active dialogue, continuously adapting our plan to meet your
            evolving needs, ensuring we're always aligned with your chosen
            direction.
          </p>
          <p className="mt-4">
            In the first session, we will delve into your current situation and
            the reasons behind seeking relationship coaching and counselling. I
            will assist you in identifying and formulating your primary goal. In
            subsequent sessions, we will continue to explore your experiences,
            aiming for greater clarity, understanding, and meaning. We will
            proactively work towards achieving your primary goal while also
            identifying and staying aligned with any sub-goals that may emerge.
          </p>

          <div className="bg-sand text-coconut p-2 mt-4">
            <h3>Commitment for Impactful Results</h3>
          </div>
          <p className="mt-2">
            For impactful and measurable results, I encourage committing to at
            least six sessions, scheduled once every two weeks. After this
            initial phase, we'll revisit your initial reasons for seeking
            assistance and evaluate our progress. We may also identify any new
            goals that have emerged based on your evolving needs. Following this
            assessment, you can choose to conclude our sessions or adjust our
            plan and continue moving forward together.
          </p>

          <div className="bg-sand text-coconut p-2 mt-4">
            <h3>Payment and Cancellation Policy</h3>
          </div>
          <p className="mt-2">I offer various packages to suit your needs:</p>
          <ul className="list-disc ml-4 mt-4">
            <li>30-minute Introduction Session: Free</li>
            <li>1.5-hours Intake Session: 120 euros</li>
            <li>Single 1-hour Coaching & Counselling Session: 80 euros</li>
            <li>
              Package of 6 1-hour Coaching & Counselling Sessions: 480 euros
            </li>
          </ul>
          <p className="mt-4">
            Invoices will be sent after each session with a payment term of 14
            days. You can reschedule or cancel a session free of charge if you
            notify me at least 48 hours in advance. If you do not show up
            without prior notice, I will unfortunately have to charge for the
            missed session. Please note that this service is not covered by
            insurance.
          </p>

          <div className="bg-sand text-coconut p-2 mt-4">
            <h3>How to Get Started</h3>
          </div>
          <p className="mt-2">
            Taking the first step towards improving your relationships and
            personal well-being is a courageous decision. If you're ready to
            begin this journey, feel free to contact me to schedule your first
            session. Together, we can work towards a happier, healthier you.
          </p>

          <Button
            scrollToId={"contact"}
            className="mt-10 sm:mt-12 bg-mud text-sand outline-none"
          >
            Plan a session
          </Button>
        </div>
      </div>
    </div>
  );
};
