import React from "react";

export const ContactContent = () => {
  return (
    <div className="bg-sand p-10 sm:p-20">
      <div className="sm:flex">
        <div className="sm:w-1/3 pr-8">
          <h2 className="text-4xl font-serif">Contact information</h2>
        </div>
        <div className="sm:w-2/3 mt-4 sm:mt-0">
          <p>
            I’m here to support you on your journey to better relationships and
            personal well-being. Whether you have questions, want to schedule a
            session, or need more information about my services, feel free to
            get in touch.
          </p>
          <h3 className="mt-4 font-medium">Get in Touch</h3>
          <p className="mt-2">
            E-mail:{" "}
            <a href="mailto:noah.counselling@gmail.com" className="text-mud">
              noah.counselling@gmail.com
            </a>
          </p>
          <p className="mt-2">
            Tel (WhatsApp):{" "}
            <a href="tel:+31631958895" className="text-mud">
              +31 (0) 63 195 8895
            </a>
          </p>

          <h3 className="mt-4 font-medium">Book a Session</h3>
          <p className="mt-2">
            Direct booking:{" "}
            <a
              href="https://calendly.com/noah-counselling"
              target="_blank"
              rel="noreferrer"
              className="text-mud"
            >
              Calendly
            </a>
          </p>

          <h3 className="mt-4 font-medium">Follow Me</h3>
          <ul className="list-disc ml-4 mt-2">
            <li>
              <a
                href="https://www.instagram.com/noahcounselling/"
                target="_blank"
                rel="noreferrer"
                className="text-mud"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCQI1HBwXk0Ywu0wzNLW8kTQ"
                target="_blank"
                rel="noreferrer"
                className="text-mud"
              >
                YouTube
              </a>
            </li>

            <li>
              <a
                href="https://www.linkedin.com/in/noah-fernandes/"
                target="_blank"
                rel="noreferrer"
                className="text-mud"
              >
                LinkedIn
              </a>
            </li>
          </ul>
          <p className="mt-4">
            I look forward to hearing from you and supporting you on your
            journey to a happier, healthier life.
          </p>
        </div>
      </div>
    </div>
  );
};
