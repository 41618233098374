import React from "react";
import { Button } from "./common/Button";

const testimonials = [
  {
    title: "G, 26 years old, Netherlands",
    description:
      "Noah's warmth and understanding have made all the difference. Unlike my past experiences, his approach felt like talking to a trusted friend. I felt an instant connection with him, which made it easy to share openly. In a time when I needed support the most, Noah guided me through challenges, offering advice on communication and self-expression. His sessions encouraged me to slow down and really tune into my feelings and needs, helping me navigate life with more clarity and authenticity.",
  },
  {
    title: "M, 46 years old, Netherlands",
    description:
      "This was my first experience with counselling, and it exceeded my expectations. I felt empowered to express myself freely, knowing I wouldn't be judged. Noah's support has been invaluable. He patiently addressed all my questions and concerns, showcasing his expertise and professional tools. With Noah's guidance, I gained clarity and a fresh perspective on various aspects of my life.",
  },
  {
    title: "F, 43 years old, Egypt",
    description:
      "I have found Noah to be an exceptionally professional coach. Despite seeking help for family issues in the past, I never gained the insights I did with Noah. Going into the coaching sessions with no expectations, I was immediately struck by his approach. Noah is deeply committed, empathetic, analytical, objective, reliable, and possesses strong communication skills. His guidance led me to profound insights and a deeper understanding of myself. I am grateful for his support and the new knowledge he has helped me uncover.",
  },
  {
    title: "N, 34 years old, India",
    description:
      "Noah is an exceptional counsellor who truly made a positive impact on my counselling journey. He has an ability to listen attentively and ask insightful questions to have a deep understanding of my challenges. He breaks down my challenges into small points and identifies the root cause. This exercise has helped me a lot and I am really grateful for it.",
  },
];

export const TestimonialsContent = () => {
  return (
    <div>
      <h3 className="text-center text-4xl font-serif">Client testimonials</h3>
      <div className="max-w-2xl m-auto text-center">
        <p className="mt-4">
          Here are testimonials from clients who have shared how they experience
          my sessions. To ensure confidentiality and enhance clarity, I have
          omitted their names and made slight adjustments to the text.
        </p>
      </div>
      {testimonials.map((offer) => {
        return (
          <div key={offer.title} className="mt-8">
            <div className="max-w-2xl m-auto text-center rounded sm:p-10 sm:border border-slate-80">
              <p>{offer.description}</p>
            </div>
          </div>
        );
      })}

      <div className="text-center mt-6">
        <Button
          scrollToId={"contact"}
          className="mt-10 sm:mt-12 bg-mud text-sand outline-none"
        >
          Plan a session
        </Button>
      </div>
    </div>
  );
};
