import React from "react";
import coconut from "../coconut.svg";
import { Button } from "./common/Button";

export const HomeContent = () => {
  return (
    <div>
      <img
        src={coconut}
        className="mt-0 sm:mt-0 w-44 m-auto"
        alt="Sprouting coconut"
      />
      <div className="text-center mt-12 max-w-md m-auto">
        <h1 className="text-4xl tracking-wider font-serif">Noah Fernandes</h1>
        <h2 className="text-2xl mt-2 font-light sm:font-thin">
          Relationship Coach & Counsellor
        </h2>

        <p className="mt-8 text-xl font-thin">
          Empowering people to cultivate healthy and authentic relationships
          with themselves and others.
        </p>

        <Button
          scrollToId={"contact"}
          className="mt-10 sm:mt-12 bg-mud text-sand outline-none"
        >
          Plan a session
        </Button>
      </div>
    </div>
  );
};
