import { AppHeader } from "./components/common/AppHeader.jsx";
import { HomeContent } from "./components/HomeContent.jsx";
import { SectionQuote } from "./components/SectionQuote";
import { AboutContent } from "./components/AboutContent.jsx";
import { ServicesContent } from "./components/ServicesContent.jsx";
import { ContactContent } from "./components/ContactContent.jsx";
import { AppFooter } from "./components/common/AppFooter.jsx";
import { AppSection } from "./components/common/AppSection.jsx";
import { CoachingCounsellingContent } from "./components/CoachingCounsellingContent.jsx";
import { TestimonialsContent } from "./components/TestimonialsContent.jsx";

import "./App.css";

function App() {
  return (
    <div className="App w-screen h-screen bg-sand overflow-auto relative">
      <AppHeader />

      <AppSection id="home" className="bg-sand flex items-center">
        <HomeContent />
      </AppSection>

      <SectionQuote
        quote="You must want to spend the rest of your life with yourself first."
        author="Rupi Kaur"
      />

      <AppSection id="coaching-counselling">
        {/* <CoachingCounsellingContent /> */}
        <AboutContent />
      </AppSection>

      {/* <SectionQuote quote="You can't pour from an empty cup, take care of yourself first." /> */}

      {/* <AppSection id="about">
        <AboutContent />
      </AppSection> */}

      <AppSection id="services" className="bg-coconut text-sand">
        <ServicesContent />
      </AppSection>

      <AppSection id="testimonials">
        <TestimonialsContent />
      </AppSection>

      <AppSection id="contact" className="bg-lawn">
        <ContactContent />
      </AppSection>

      <AppFooter />
    </div>
  );
}

export default App;
