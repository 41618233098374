import React from "react";
import profile from "../profile.png";
import { Button } from "./common/Button";

export const AboutContent = () => {
  return (
    <div>
      <div className="sm:flex">
        <div className="sm:w-2/6 sm:pr-8">
          <img src={profile} alt="Noah Fernandes profile" className="rounded" />
        </div>
        <div className="sm:w-4/6">
          <p className="text-xl font-light sm:font-thin mt-4 sm:mt-0">
            Welcome to my corner of the web. I'm Noah, and I'm genuinely pleased
            that you're here, taking this proactive step towards building better
            relationships.
          </p>
          <p className="mt-4">
            I firmly believe that the key to strong connections starts with
            cherishing the relationship we have with ourselves. As a
            Relationship Coach and Counsellor, I support and guide individuals
            in achieving this, empowering them on their journey.
          </p>
          <p className="mt-4">
            Let me share a bit about myself. I hail from the vibrant state of
            Goa in India, where I grew up immersed in its rich culture of music,
            cuisine, nature, festivities, and leisure. In 2009, I moved to
            Singapore, where I spent seven incredible years honing my skills as
            a creative software engineer. In 2016, I decided to settle in the
            beautiful city of Amsterdam, Netherlands. My life has been a
            colourful blend of diverse experiences, challenges, and
            accomplishments, from exploring different roles to immersing myself
            in various cultures. Throughout this journey, I've discovered a
            natural curiosity and openness to others' experiences, along with a
            genuine desire for them to succeed and be happy.
          </p>
          <p className="mt-4">
            In 2021, my life took a significant turn following a divorce and the
            realisation that I was in multiple relationships and environments
            where I could not be my full authentic self, causing me stress and
            unhappiness. I had to let go of many things that weren't working for
            me, including a stable but unfulfilling career, as well as
            friendships and relationships that were holding me back. This period
            of emptiness led me to deeply question my identity, and I decided to
            pursue my passion for self-development and positive psychology. I
            adopted a healthy diet and immersed myself in activities like
            meditation, yoga, and swimming, dedicating more time to building a
            healthy relationship with myself. Through my hard work, I
            illuminated my blind spots, identified my needs, desires, and
            boundaries, and learnt to honour, respect, and communicate them with
            others. Consequently, I gradually witnessed, with pleasant surprise,
            my relationships with others improving on their own. Bit by bit, I
            began to rebuild my life with the things I truly valued, fostering
            healthier, supportive connections and a stronger community that
            empowered me to grow.
          </p>
          <p className="mt-4">
            Have you ever felt disconnected from yourself or others? I
            understand that feeling, and I am here to help. Having experienced
            these challenges first-hand, I know how daunting it can be to let go
            of an old identity and rebuild oneself. But I can assure you, it’s
            worth it. My goal is to support and guide you through this
            transition, helping you rebuild a connection with yourself and,
            ultimately, with those around you. Together, we can improve the
            quality of your relationships, leading to a happier, richer life.
          </p>

          <Button
            scrollToId={"contact"}
            className="mt-6 sm:mt-12 bg-mud text-sand"
          >
            Plan a session
          </Button>
        </div>
      </div>
    </div>
  );
};
